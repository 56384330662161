import defaultPostTypeConfig from '../post'
import capitalize from '../../utils/capitalize'

export default function getConstants(p = {}) {

    const name = 'page'

    const n = name
    const N = capitalize(n)
    const ns = (name.endsWith('y')) ? name.slice(0, -1) + 'ies' : name + 's'
    const Ns = capitalize(ns)

    const {
        labels,
        menus,
        messages,
        routes,
        titles
    } = defaultPostTypeConfig.getConstants({
        ...p,
        name
    })

    return {
        labels: {
            ...labels,
            ['new' + N + 'SubmitLabel']: 'Mentés',
            ['restore' + N + 'SubmitLabel']: 'Visszaállítás',
            ['save' + N + 'SubmitLabel']: 'Mentés',
            ['cancel' + N + 'Text']: 'Mégsem',
            ['delete' + N + 'Text']: 'Törlés',
            ['delete' + Ns + 'Text']: 'Oldalak törlése',
            ['ban' + N + 'Text']: 'Tiltás',
            ['approve' + N + 'Text']: 'Jóváhagyás',
            ['protection' + N + 'Text']: 'Védettnek jelölés',
            ['removeProtection' + N + 'Text']: 'Védettség megszüntetése',
            ['select' + N + 'Text']: 'Kiválasztás',
            ['add' + N + 'Text']: 'Hozzáadás',
            ['moreFromMy' + Ns + 'Title']: 'További oldalaim',
            ['moreFromUser' + Ns + 'Title']: 'További oldalak',

            [ns + 'SortRelevance']: 'Relevancia',
            [n + 'LabelsMore']: 'További szerzők'
        },
        menus: {
            ...menus,
            ['user' + Ns + 'Menu']: 'Oldalak',
            ['my' + Ns + 'Menu']: 'Oldalaim',
            ['new' + N + 'Menu']: 'Új oldal',
            [ns + 'Menu']: 'Oldalak',
            ['deleted' + Ns + 'Menu']: 'Törölt oldalak',
            ['banned' + Ns + 'Menu']: 'Véglegesen törölt oldalak',
            ['protection' + Ns + 'Menu']: 'Védett oldalak',
            ['missingData' + Ns + 'Menu']: 'Hiányzó adatok',
            ['lowAuthorStatus' + Ns + 'Menu']: 'Szerző alacsony státusszal',

            ['edit' + N + 'Menu']: 'Szerkesztés',
            ['delete' + N + 'Menu']: 'Törlés',
            ['ban' + N + 'Menu']: 'Tiltás',
            ['approve' + N + 'Menu']: 'Jóváhagyás',
            ['protection' + N + 'Menu']: 'Védettnek jelölés',
            ['removeProtection' + N + 'Menu']: 'Védettség törlése'
        },
        messages: {
            ...messages,
            ['new' + N + 'SuccessMessage']: 'Az új oldal mentésre került',
            ['save' + N + 'SuccessMessage']: 'A oldal mentésre került',
            ['delete' + N + 'Success']: 'A oldal törölve lett',
            ['delete' + Ns + 'Success']: 'A oldalak törölve lettek',
            ['delete' + N + 'Question']: 'Biztosan törli az oldalt?',
            ['delete' + Ns + 'Question']: 'Biztosan törli az oldalakat?',
            ['ban' + N + 'Success']: 'A oldal véglegesen törölve lett',
            ['ban' + N + 'Question']: 'Biztos, hogy az oldalt véglegesen törölni akarod?',
            ['approve' + N + 'Success']: 'A oldal jóvá lett hagyva',
            ['approve' + N + 'Question']: 'Biztosan jóváhagyod az oldalt',
            ['protection' + N + 'Success']: 'A oldal védettnek jelölve',
            ['protection' + N + 'Question']: 'Biztosan védettnek jelölöd az oldalt?',
            ['removeProtection' + N + 'Success']: 'A védettnek jelölés törölve lett',
            ['removeProtection' + N + 'Question']: 'Biztosan törlöd a védettnek jelölést?',
            ['thereAreNo' + Ns]: 'Nincsenek oldalak'
        },
        routes: {
            ...routes
        },
        titles: {
            ...titles,
            [n + 'Title']: 'Oldal',
            [ns + 'Title']: 'Oldalak',
            ['new' + N + 'Title']: 'Új oldal',
            ['edit' + N + 'Title']: 'Oldal szerkesztése',
            ['user' + Ns + 'Title']: 'Oldalak',
            ['userDeleted' + Ns + 'Title']: 'Törölt oldalak',
            ['userBanned' + Ns + 'Title']: 'Véglegesen törölt oldalak',
            ['my' + Ns + 'Title']: 'Oldalaim',
            ['myDeleted' + Ns + 'Title']: 'Törölt oldalaim',
            ['myBanned' + Ns + 'Title']: 'Véglegesen törölt oldalaim',

            ['dialogDelete' + N + 'Title']: 'Oldal törlése',
            ['dialogBan' + N + 'Title']: 'Oldal törlése véglegesen',
            ['dialogApprove' + N + 'Title']: 'Oldal jóváhagyása',
            ['dialogProtection' + N + 'Title']: 'Oldal védettnek jelölése',
            ['dialogRemoveProtection' + N + 'Title']: 'Oldal védettségének megszüntetése',

            ['dialogDelete' + Ns + 'Title']: 'Oldalak törlése',

            ['statusBanned' + N + 'Title']: 'Véglegesen törölt',
            ['statusDeleted' + N + 'Title']: 'Törölt',
            ['statusAuthorDeleted' + N + 'Title']: 'Szerző törölve',
            ['statusMissingData' + N + 'Title']: 'Hiányzó adatok',
            ['statusApproved' + N + 'Title']: 'Jóváhagyott',
            ['statusFeatured' + N + 'Title']: 'Védett',
            ['statusCreated' + N + 'Title']: 'Létrehozva',

            ['banned' + Ns + 'Title']: 'Véglegesen törölt oldalak',
            ['deleted' + Ns + 'Title']: 'Törölt oldalak',
            ['missingData' + Ns + 'Title']: 'Hiányzó adatok',
            ['protected' + Ns + 'Title']: 'Védett oldalak',
            ['lowAuthorStatus' + Ns + 'Title']: 'Szerző alacsony státusszal',

            ['select' + Ns]: 'Oldalak kiválasztása',
            ['select' + N]: 'Oldal kiválasztása'
        }
    }
}
