import defaultPostTypeConfig from '../post'
import capitalize from '../../utils/capitalize'

export default function getConstants(p = {}) {

    const name = 'category'

    const n = name
    const N = capitalize(n)
    const ns = (name.endsWith('y')) ? name.slice(0, -1) + 'ies' : name + 's'
    const Ns = capitalize(ns)

    const {
        labels,
        menus,
        messages,
        routes,
        titles
    } = defaultPostTypeConfig.getConstants({
        ...p,
        name
    })

    return {
        labels: {
            ...labels,
            ['new' + N + 'SubmitLabel']: 'Mentés',
            ['restore' + N + 'SubmitLabel']: 'Visszaállítás',
            ['save' + N + 'SubmitLabel']: 'Mentés',
            ['cancel' + N + 'Text']: 'Mégsem',
            ['delete' + N + 'Text']: 'Törlés',
            ['delete' + Ns + 'Text']: 'Kategóriák törlése',
            ['ban' + N + 'Text']: 'Tiltás',
            ['approve' + N + 'Text']: 'Jóváhagyás',
            ['protection' + N + 'Text']: 'Védettnek jelölés',
            ['removeProtection' + N + 'Text']: 'Védettség megszüntetése',
            ['select' + N + 'Text']: 'Kiválasztás',
            ['add' + N + 'Text']: 'Hozzáadás',
            ['date' + N + 'Format']: ({ dateText = '' }) => {
                if (!dateText) {
                    return dateText
                }
                try {
                    return new Date(dateText).toLocaleString('hu-HU')
                } catch (e) {
                }
                return dateText
            },

            [ns + 'More']: 'Továbbiak',
            [ns + 'SortRelevance']: 'Relevancia'
        },
        menus: {
            ...menus,
            ['user' + Ns + 'Menu']: 'Kategóriák',
            ['my' + Ns + 'Menu']: 'Kategóriáim',
            ['new' + N + 'Menu']: 'Új kategória',
            [ns + 'Menu']: 'Kategóriák',
            ['deleted' + Ns + 'Menu']: 'Törölt kategóriák',
            ['banned' + Ns + 'Menu']: 'Véglegesen törölt kategóriák',
            ['protection' + Ns + 'Menu']: 'Védett kategóriák',
            ['missingData' + Ns + 'Menu']: 'Hiányzó adatok',
            ['lowAuthorStatus' + Ns + 'Menu']: 'Szerző alacsony státusszal',

            ['edit' + N + 'Menu']: 'Szerkesztés',
            ['delete' + N + 'Menu']: 'Törlés',
            ['ban' + N + 'Menu']: 'Tiltás',
            ['approve' + N + 'Menu']: 'Jóváhagyás',
            ['protection' + N + 'Menu']: 'Védettnek jelölés',
            ['removeProtection' + N + 'Menu']: 'Védettség törlése'
        },
        messages: {
            ...messages,
            ['new' + N + 'SuccessMessage']: 'Az új kategória mentésre került',
            ['save' + N + 'SuccessMessage']: 'A kategória mentésre került',
            ['delete' + N + 'Success']: 'A kategória törölve lett',
            ['delete' + Ns + 'Success']: 'A kategóriák törölve lettek',
            ['delete' + N + 'Question']: 'Biztosan törli a cimkét?',
            ['delete' + Ns + 'Question']: 'Biztosan törli a kategóriáket?',
            ['ban' + N + 'Success']: 'A kategória véglegesen törölve lett',
            ['ban' + N + 'Question']: 'Biztos, hogy a cimkét véglegesen törölni akarod?',
            ['approve' + N + 'Success']: 'A kategória jóvá lett hagyva',
            ['approve' + N + 'Question']: 'Biztosan jóváhagyod a cimkét',
            ['protection' + N + 'Success']: 'A kategória védettnek jelölve',
            ['protection' + N + 'Question']: 'Biztosan védettnek jelölöd a cimkét?',
            ['removeProtection' + N + 'Success']: 'A védettnek jelölés törölve lett',
            ['removeProtection' + N + 'Question']: 'Biztosan törlöd a védettnek jelölést?',
            ['thereAreNo' + Ns]: 'Nincsenek kategóriák'
        },
        routes: {
            ...routes
        },
        titles: {
            ...titles,
            [n + 'Title']: 'Kategória',
            [ns + 'Title']: 'Kategóriák',
            ['new' + N + 'Title']: 'Új kategória',
            ['edit' + N + 'Title']: 'Kategória szerkesztése',
            ['user' + Ns + 'Title']: 'Kategóriák',
            ['userDeleted' + Ns + 'Title']: 'Törölt kategóriák',
            ['my' + Ns + 'Title']: 'Kategóriáim',
            ['myDeleted' + Ns + 'Title']: 'Törölt cimkéim',

            ['dialogDelete' + N + 'Title']: 'Kategória törlése',
            ['dialogBan' + N + 'Title']: 'Kategória törlése véglegesen',
            ['dialogApprove' + N + 'Title']: 'Kategória jóváhagyása',
            ['dialogProtection' + N + 'Title']: 'Kategória védettnek jelölése',
            ['dialogRemoveProtection' + N + 'Title']: 'Kategória védettségének megszüntetése',

            ['dialogDelete' + Ns + 'Title']: 'Kategóriák törlése',

            ['statusBanned' + N + 'Title']: 'Véglegesen törölt',
            ['statusDeleted' + N + 'Title']: 'Törölt',
            ['statusAuthorDeleted' + N + 'Title']: 'Szerző törölve',
            ['statusMissingData' + N + 'Title']: 'Hiányzó adatok',
            ['statusApproved' + N + 'Title']: 'Jóváhagyott',
            ['statusFeatured' + N + 'Title']: 'Védett',
            ['statusCreated' + N + 'Title']: 'Létrehozva',

            ['banned' + Ns + 'Title']: 'Véglegesen törölt kategóriák',
            ['deleted' + Ns + 'Title']: 'Törölt kategóriák',
            ['missingData' + Ns + 'Title']: 'Hiányzó adatok',
            ['protected' + Ns + 'Title']: 'Védett kategóriák',
            ['lowAuthorStatus' + Ns + 'Title']: 'Szerző alacsony státusszal',

            ['select' + Ns]: 'Kategóriák kiválasztása',
            ['select' + N]: 'Kategória kiválasztása'
        }
    }
}
