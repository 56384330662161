import App from '../../components/App'
import capitalize from '../../utils/capitalize'

import { runPostTypesConfig } from '../index'
import defaultPostTypeConfig from '../post'
import collectRouteParams from '../../utils/collectRouteParams'

import { defaultGetPostFunction as getPost } from '../post/contents'

export default function setContents(props) {

    const {
        wapp,
        routes,
        titles,
        name = 'user',
        getTitle = function({ title }) {
            return title
        },
        urlParams = [
            '/page/:pagination',
            '/sort/:sort',
            '/limit/:limit',
            '/professions/:professions'
        ]
    } = props

    const n = name
    const ns = (n.endsWith('y')) ? n.slice(0, -1) + 'ies' : n + 's'
    const N = capitalize(n)

    let reqUserForPost = null

    function getPostTitle(p) {
        const wappResponse = p.res.wappResponse
        const post = wappResponse.store.getState('res.responses.' + n + 'FindById')
        const route = wappResponse.route
        const { params } = route
        let title = titles[n + 'Title']
        if (post && params._id === post._id && post.title) {
            switch (params.page) {
                case 'edit':
                    title = titles['edit' + N + 'Title'] + ' | ' + post.title
                    break
                case 'posts':
                    switch (params.pageType) {
                        case 'deleted':
                            title = titles[n + 'DeletedPostsTitle'] + ' | ' + post.title
                            break
                        case 'banned':
                            title = titles[n + 'BannedPostsTitle'] + ' | ' + post.title
                            break
                        default:
                            title = titles[n + 'PostsTitle'] + ' | ' + post.title
                    }
                    break
                default:
                    title = post.title
            }
        }
        return getTitle({ ...p, title })
    }

    wapp.contents.add({
        [n]: {
            render: App,
            renderType: 'react',
            title: getPostTitle,
            description: function(p) {
                const { wapp, req, res } = p
                const wappResponse = res.wappResponse
                const post = wappResponse.store.getState('res.responses.' + n + 'FindById')
                if (post?._id && post?.title) {
                    return titles['get' + N + 'Description'](post.title)
                }
                const config = wapp.getTargetObject().config
                const { description } = config
                return (description) ? description : getPostTitle({ wapp, req, res }).split(' | ')[0]
            },
            request: async function({ wapp, req, res }) {
                const wappResponse = res.wappResponse
                const post = wappResponse.store.getState('res.responses.' + n + 'FindById')
                const route = wappResponse.route
                const { params } = route
                const { _id } = params

                const statusManager = wapp.getTargetObject().postTypes.findPostType({ name: n }).statusManager

                await runPostTypesConfig({ action: 'requestFor' + N + 'Page', p: { wapp, req, res, statusManager } })

                if ((_id && !post) ||
                    (_id && post && post._id !== _id) ||
                    (_id && reqUserForPost?._id !== req.wappRequest.user?._id)) {
                    reqUserForPost = { _id: req.wappRequest.user?._id }
                    return await wapp.requests.send({ requestName: n + 'FindById', args: { _id: _id }, req, res })
                }
            },
            image: (context) => {

                const post = getPost({ ...context, name })
                let thumb = (post?._id && post.thumb) ? post.thumb : null

                if (thumb && thumb.startsWith('/thumb')) {
                    thumb = thumb.replace('/thumb', '/thumb/2')
                }

                if (thumb) {

                    try {

                        const { req } = context
                        const hostname = req.wappRequest.hostname
                        const protocol = req.wappRequest.protocol
                        const url = new URL(protocol + '://' + hostname + thumb)
                        if (url.searchParams.get('crop')) {

                            const crop = JSON.parse(url.searchParams.get('crop'))
                            const width = crop.croppedAreaPixels.width
                            const height = crop.croppedAreaPixels.height
                            const maxDim = 800
                            const scale = Math.min(maxDim / width, maxDim / height)

                            return {
                                width: Math.round(width * scale),
                                height: Math.round(height * scale),
                                thumb
                            }
                        }

                    } catch (e) {

                    }

                }

                return thumb
            },
            imageAlt: (context) => {
                const post = getPost({ ...context, name })
                return post?.title || ''
            }
        },
        [ns]: {
            render: App,
            renderType: 'react',
            title: (p) => wapp.contents.get('posts').title({ ...p, name }),
            description: (p) => wapp.contents.get('posts').description({ ...p, name }),
            request: async function({ wapp, req, res }) {

                const wappResponse = res.wappResponse
                const wappRequest = req.wappRequest
                const route = wappResponse.route
                const { params } = route
                const query = wappRequest.query

                const listData = wappResponse.store.getState('res.graphql.query.' + name + 'FindMany.listData') || {}
                const listDataSort = listData.sort || []

                /*

                word search for search of users:

                    - accented characters and capital letters do not count
                    - it only searches for words, not word parts, there is an OR relationship between the words
                    - mongodb divides email addresses into parts, so if you like to search for a complete match, use quotation marks "email@email.hu"
                    - sorting by order of weight: email, businessName, name.last, name.first

                */

                if (query.search) {
                    listDataSort.push({ key: 'TEXTSCORE' })
                }

                const defaultSort = listDataSort[0]?.key || ''

                let sort = (params.sort && listDataSort && listDataSort.map((p) => p.key).find((key) => key === params.sort)) || defaultSort

                if (sort === 'TEXTSCORE') {
                    sort = ''
                }

                const page = params.page
                const pagination = !isNaN(Number(params.pagination)) && Number(params.pagination) ? Number(params.pagination) : 1

                const perPageFormData = listData.perPage
                const limitPerPage = perPageFormData.limit || 100
                const defaultPerPage = perPageFormData.default || 20
                const perPage = (params.limit && !isNaN(Number(params.limit)) && Number(params.limit) <= limitPerPage && Number(params.limit) > 1) ? Number(params.limit) : defaultPerPage

                const postStatusManager = wapp.getTargetObject().postTypes.findPostType({ name: n }).statusManager

                const formData = wappResponse.store.getState('res.graphql.query.' + name + 'FindMany.formData')

                const filter = urlParams.reduce((o, urlParamText) => {
                    if (!urlParamText.startsWith('/page/:') && !urlParamText.startsWith('/sort/:') && !urlParamText.startsWith('/limit/:')) {
                        const paramNameN = urlParamText.startsWith('/') ? 1 : 0
                        const paramName = urlParamText.split('/')[paramNameN]
                        if (params[paramName] && formData['filter.' + paramName]) {
                            const { schemaType, multiple } = formData['filter.' + paramName]
                            let value = params[paramName]
                            if (multiple) {
                                try {
                                    value = (value.startsWith('[') && value.endsWith(']')) ? JSON.parse(value) : value.split(',')
                                } catch (e) {
                                }
                            } else {
                                if (schemaType === 'Float' || schemaType === 'Int') {
                                    value = Number(value)
                                }
                            }
                            if (typeof value !== 'undefined') {
                                o[paramName] = value
                            }
                        }
                    }
                    return o
                }, {})

                const status =
                    (page === 'banned') ?
                        { gt: postStatusManager.getBannedStatus() - 1, lt: postStatusManager.getDeletedStatus() } :
                        (page === 'deleted') ?
                            { gt: postStatusManager.getDeletedStatus() - 1, lt: postStatusManager.getDefaultStatus() } :
                            (page === 'missingdata') ?
                                { gt: postStatusManager.getDefaultStatus() - 1, lt: postStatusManager.getMinStatus() } :
                                (page === 'protected') ?
                                    { gt: postStatusManager.getFeaturedStatus() - 1 } :
                                    { gt: postStatusManager.getMinStatus() - 1 }

                await wapp.requests.send({
                    requestName: n + 'FindMany',
                    args: {
                        filter: {
                            ...(query.search) ? { search: query.search } : {},
                            ...(Object.keys(filter).length) ? filter : {},
                            _operators: {
                                _status: status
                            }
                        },
                        ...(sort) ? { sort } : {},
                        page: pagination,
                        perPage
                    },
                    req,
                    res
                })

            }
        }
    })

    const paths = collectRouteParams({
        startRoutes: [
            { path: routes[n + 'Route'] + '/:_id/:page', contentName: n },
            { path: routes[n + 'Route'] + '/:_id/:page/:pageType', contentName: n },
            { path: routes[ns + 'Route'], contentName: ns },
            { path: routes[ns + 'Route'] + '/:page', contentName: ns }
        ],
        params: [
            ...urlParams
        ]
    })

    async function redirectActionMeToUser(p) {

        const { req, res, ...rest } = p

        let r = { ...rest }

        if (!req.wappRequest.user) {

            const loginUrl = routes.accountRoute + '/login?redirect=' + encodeURIComponent(req.url)

            if (wapp.target === 'node') {

                res.wappResponse.status(302)
                res.writeHead(302, {
                    'Location': loginUrl
                })
                res.end()

                return

            } else {

                req.wappRequest.query.redirect = req.url
                req.query.redirect = req.url
                req.url = routes.accountRoute + '/login?redirect=' + encodeURIComponent(req.url)
                req.path = routes.accountRoute + '/login'

                r = await wapp.router.routeManager.resolve({
                    path: routes.accountRoute + '/login',
                    req,
                    res
                })

                window.history.replaceState(window.history.state, undefined, req.url)

                if (r.status === 200) {
                    r.status = 302
                }

                setTimeout(() => {
                    //it was necessary for normal operation, otherwise the redirection would not work because the component does not reload
                    window.location.href = req.url
                })

            }
        } else {

            if (wapp.target === 'node') {

                const userUrl = req.url.replace('/me', routes[name + 'Route'] + '/' + req.wappRequest.user._id)

                res.wappResponse.status(302)
                res.writeHead(302, {
                    'Location': userUrl
                })
                res.end()

                return

            } else {

                req.url = req.url.replace('/me', routes[name + 'Route'] + '/' + req.wappRequest.user._id)
                //req.requestPath = req.requestPath.replace('/me', routes[name + 'Route'] + '/' + req.wappRequest.user._id)
                req.path = req.path.replace('/me', routes[name + 'Route'] + '/' + req.wappRequest.user._id)

                r = await wapp.router.routeManager.resolve({
                    path: req.path,
                    req,
                    res
                })

                window.history.replaceState(window.history.state, undefined, req.url)

                if (r.status === 200) {
                    r.status = 302
                }
            }


        }

        return r
    }


    wapp.router.add([

        { path: '/me/*', contentName: n, action: redirectActionMeToUser },

        { path: routes[n + 'Route'], contentName: n },
        { path: routes[n + 'Route'] + '/:_id', contentName: n },

        ...paths

    ])

    return {
        name: n
    }

}

export async function requestForUserPage() {
    return null
}

export function adminMenu(p) {
    const name = 'user'
    const menu = defaultPostTypeConfig.adminMenu({ ...p, name })

    const { routes } = p.appContext

    menu.items = menu.items.filter((item) => {
        if (item.href === routes[name + 'Route'] + '/new') {
            return false
        }
        return (item.href === 'string' && !item.href.match('/lowauthorstatus')) || typeof item.href !== 'string'
    })
    menu.order = 20
    return menu
}
