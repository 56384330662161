import capitalize from '../../utils/capitalize'

export function getCategoryMenu({ parentRoute = '', utils, appContext, name }) {

    const N = capitalize(name)

    const categories = utils.getGlobalState('res.responses.categories')

    const menuItems = (categories?.items.length ? categories.items.filter((menu) => menu.showOnMenu) : [])

    return (menuItems.length ? menuItems.filter((menu) => !menu.parentCategories?.length) : [])
        .map((category) => {

            const items = [
                ...menuItems.filter((submenu) => {
                    const parentCategories = submenu.parentCategories ? submenu.parentCategories.map((parentCategory) => parentCategory?._id || parentCategory) : []
                    return parentCategories.indexOf(category._id) > -1
                }).map((category) => {
                    return {
                        label: category.title,
                        href: parentRoute + '/categories/' + category.slug
                    }
                })
            ]

            return {
                label: category.title,
                ...items?.length ? {
                    itemsHeading: {
                        label: appContext.menus[name + capitalize(category.slug) + 'CategoryAll'] ||
                            (appContext.menus['get' + N + 'CategoryAll'] ? appContext.menus['get' + N + 'CategoryAll']({ category }) : '') ||
                            category.subtitle ||
                            category.title,
                        href: parentRoute + '/categories/' + category.slug
                    },
                    items
                } : {
                    href: parentRoute + '/categories/' + category.slug
                }
            }
        })
}
