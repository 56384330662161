import React from 'react'

import DefaultArchiveComponent from '../post/DefaultArchiveComponent'
import DefaultComponent from './DefaultComponent'

export default function getComponent(p) {

    const { context, appContext, name = 'user' } = p
    const { routes } = appContext

    const ns = (name.endsWith('y')) ? name.slice(0, -1) + 'ies' : name + 's'

    const { res, req } = context
    const route = res.wappResponse.route
    const { params } = route
    const { _id } = params
    const requestPath = route.requestPath
    const user = req.wappRequest.user

    const isAdmin = user && user._status_isFeatured
    const isAuthor = ((user?._id && user._id.toString() === _id))
    const isAuthorOrAdmin = (isAuthor || isAdmin)

    if (requestPath.startsWith(routes[ns + 'Route']) && !isAdmin) {
        return null
    }

    if (requestPath.startsWith(routes[name + 'Route']) && !isAuthorOrAdmin) {
        return null
    }


    const {
        archiveComponentProps = {
            disableAvatars: true,
            selectable: false,
            tableProps: {
                showLabelTitle: true
            }
        },
        componentProps = {},
        Component = DefaultComponent,
        ArchiveComponent = DefaultArchiveComponent,
        ...rest
    } = p

    return (
        requestPath.startsWith(routes[ns + 'Route'])
    ) ?
        (props) =>
            <ArchiveComponent
                {...rest}
                {...archiveComponentProps}
                {...props}
                name={name}
            /> :
        (requestPath.startsWith(routes[name + 'Route'])) ?
            (props) => {

                const cProps = {
                    ...rest,
                    ...componentProps,
                    ...props
                }

                return (
                    <Component
                        {...cProps}
                        name={name}
                    />
                )

            }
            : null
}
