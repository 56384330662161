export default function getCategory(props) {

    const { res } = props

    const wappResponse = res.wappResponse
    const route = wappResponse.route

    const { params } = route

    if (params.categories) {
        const categories = wappResponse.store.getState('res.responses.categories')
        return categories?.items.length ? categories.items.find((category) => category.slug === params.categories) : null
    }

    return null

}
